import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Masthead from '../components/masthead/masthead'
import ProductFeature from '../components/productfeature/productfeature'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import {linkResolver} from "../utils/linkResolver";
import htmlSerializer from "../utils/htmlSerializer";

export const query = graphql`
  {
    prismic {
      allProducts {
        edges {
          node {
            image
            metatitle
            metadescription
            productfeatures {
              productfeaturecontent
              productfeatureicon
            }
            productfeaturestitle
            title
            productcontenttitle
            productcontent
            productcontenttitle2
            productcontent2
            productcontenttitle3
            productcontent3
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const doc = data.prismic.allProducts.edges.slice(0, 1).pop()
  if (!doc) return null
  const title = RichText.asText(doc.node.title)
  const metaTitle = doc.node.metatitle ? RichText.asText(doc.node.metatitle) : title
  const metaDescription = RichText.asText(doc.node.metadescription)
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname="/product"
      />
      <Masthead title={title}></Masthead>
      <ProductFeature doc={doc} />
      {doc.node.productcontent && (
        <div className="c-content c-content--risk">
          <div className="container-fluid">
            <div className="row">
              <div className="c-content__container">
                <h1 className="c-content__title">{ RichText.asText(doc.node.productcontenttitle) }</h1>
                <div className="c-content__text cms-content">
                  {RichText.render(doc.node.productcontent, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {doc.node.productcontent2 && (
        <div className="c-content c-content--risk">
          <div className="container-fluid">
            <div className="row">
              <div className="c-content__container">
                <h1 className="c-content__title">{ RichText.asText(doc.node.productcontenttitle2) }</h1>
                <div className="c-content__text cms-content">
                  {RichText.render(doc.node.productcontent2, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {doc.node.productcontent3 && (
        <div className="c-content c-content--risk">
          <div className="container-fluid">
            <div className="row">
              <div className="c-content__container">
                <h1 className="c-content__title">{ RichText.asText(doc.node.productcontenttitle3) }</h1>
                <div className="c-content__text cms-content">
                  {RichText.render(doc.node.productcontent3, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}
