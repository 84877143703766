import React from 'react'
import 'intersection-observer'
import IsVisible from 'react-is-visible'
import { RichText } from 'prismic-reactjs'
import Icon from '../icon/icon'
import { linkResolver } from '../../utils/linkResolver'
import htmlSerializer from '../../utils/htmlSerializer'

class ProductFeature extends React.Component {
  render () {
    const doc = this.props.doc.node
    return (
      <div className="c-product-feature">
        <div className="c-product-feature__header">
          <div className="c-product-feature__particles-container">
            <video muted loop playsInline autoPlay className="c-product-feature__particles-video">
              <source src="/videos/particles.webm" type='video/webm;codecs="vp8, vorbis"'/>
              <source src="/videos/particles.mp4" type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>
          </div>
          {doc.image && (
            <div className="c-product-feature__header-image">
              <picture>
                <source srcSet={doc.image.small.url} data-srcset={doc.image.url} media="(min-width: 1200px)"/>
                <source srcSet={doc.image.small.url} data-srcset={doc.image.medium.url} media="(min-width: 768px)"/>
                <img src={doc.image.small.url} className="lazyload" alt=""/>
              </picture>
            </div>
          )}
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="c-product-feature__list-header">
              <h2 className="c-product-feature__list-header-title">{RichText.asText(doc.productfeaturestitle)}</h2>
            </div>
          </div>
          <div className="row">
            {doc.productfeatures.map((feature, index) => {
              if (feature === null) return null
              return (
                <div className="c-product-feature__list-item" key={`feature-${ index }`}>
                  <div className="c-product-feature__list-wrapper">
                    <IsVisible once>
                      {isVisible =>
                        <div className={`c-product-feature__list-icon${ isVisible ? ' is-visible' : '' }`}>
                          <Icon icon={feature.productfeatureicon}></Icon>
                        </div>
                      }
                    </IsVisible>
                    <div className="c-product-feature__list-summary cms-content">
                      {RichText.render(feature.productfeaturecontent, linkResolver, htmlSerializer)}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default ProductFeature
